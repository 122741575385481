



















































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { Demand } from "@/store/models/DemandAndSupply";
import {
  CropVarietyStore,
  CropStore,
  DemandSupplyStore,
  MetaStore,
  JWTStore
} from "@/store/modules";
import { MetaInfo } from "vue-meta";
import { convertADtoBS, nepaliDate } from "@/store/modules/dateConverter";

@Component({
  components: {},
  metaInfo(): MetaInfo {
    return {
      title: "Sale List",
      meta: [
        { name: "keyword", content: "Sqcc sale list" },
        {
          name: "description",
          content: "List of sales of seeds",
          vmid: "demandList"
        }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchCompany: string | null = null;
  searchCode: string | null = null;
  fiscalYearId: number | null = null;
  cropId: number | null = null;
  cropVarietyId: number | null = null;
  seedTypeId: number | null = null;
  districtId: number | null = null;
  pageNum: number = 1;
  clusterId: number = 0;
  language: string = "";
  loading: boolean = false;
  status: string | null = null;
  role: string = "";
  dialogVisible: boolean = false;

  get dataList() {
    return DemandSupplyStore.PaginatedSale;
  }

  indexMethod(index: number) {
    let i = (this.dataList.page - 1) * this.dataList.page_size + index + 1;
    return i;
  }

  nepaliDate(date: string) {
    return nepaliDate(date);
  }

  get districts() {
    return MetaStore.District;
  }

  get fiscalyears() {
    return MetaStore.FiscalYear;
  }

  get crops() {
    return CropStore.CropNameList;
  }

  get cropVarieties() {
    let varieties = CropVarietyStore.CropVarieties;
    if (this.cropId && this.cropId !== null) {
      varieties = varieties.filter(x => x.croptypeid == this.cropId);
    }
    return varieties;
  }

  get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  changeCrop() {
    this.cropVarietyId = null;
  }

  thousandSeprator(amount: number) {
    if (amount == null) {
      return null;
    }
    amount = Number(amount);
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  search() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    query = this.routerReplacer();
    DemandSupplyStore.getAdminSales(query);
  }

  clearSearch() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    this.fiscalYearId = null;
    this.cropId = null;
    this.cropVarietyId = null;
    this.seedTypeId = null;
    this.districtId = null;
    this.status = null;
    this.searchCode = null;
    this.searchCompany = null;
    query = this.routerReplacer();
    DemandSupplyStore.getAdminSales(query);
  }

  routerReplacer() {
    let query: any = {};
    if (this.pageNum) {
      query["page"] = String(this.pageNum);
    }
    if (this.fiscalYearId != null) {
      query["fiscalYearId"] = String(this.fiscalYearId);
    }
    if (this.cropId != null) {
      query["cropId"] = String(this.cropId);
    }
    if (this.cropVarietyId != null) {
      query["cropVarietyId"] = String(this.cropVarietyId);
    }
    // if role is ISMAdmin (Improved Seed Manager Admin), then pass seedTypeId = 4 (id of seedtype 'Improved Seed' )
    if (this.role == "ISMAdmin") {
      query["seedTypeId"] = String(4);
    } else if (this.seedTypeId != null) {
      query["seedTypeId"] = String(this.seedTypeId);
    }
    if (this.districtId != null) {
      query["districtId"] = String(this.districtId);
    }
    if (this.status != null) {
      query["status"] = String(this.status);
    }
    if (this.searchCompany != null) {
      query["searchCompany"] = String(this.searchCompany);
    }
    if (this.searchCode != null) {
      query["searchCode"] = String(this.searchCode);
    }
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: query
        })
        .catch(error => {});
    }
    return query;
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    this.loading = true;
    let query = this.routerReplacer();
    await DemandSupplyStore.getAdminSales(query);
    this.loading = false;
  }

  toogleExpand(row: any) {
    let $table = this.$refs.dataTable as any;
    $table.toggleRowExpansion(row);
  }

  viewBill() {
    this.dialogVisible = true;
  }

  private async created() {
    MetaStore.getAllDistrict();
    MetaStore.getAllFiscalYear();
    CropStore.getAllCropName();
    CropVarietyStore.getAllCropVariety();
    DemandSupplyStore.getAllSeedType();

    this.role = JWTStore.role;
    this.language = this.$i18n.locale;

    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
    if (this.$route.query.cropId) {
      this.cropId = Number(this.$route.query.cropId);
    }
    if (this.$route.query.cropVarietyId) {
      this.cropVarietyId = Number(this.$route.query.cropVarietyId);
    }
    if (this.$route.query.seedTypeId) {
      this.seedTypeId = Number(this.$route.query.seedTypeId);
    }
    if (this.$route.query.status) {
      this.status = String(this.$route.query.status);
    }
    if (this.$route.query.searchCompany) {
      this.searchCompany = String(this.$route.query.searchCompany);
    }
    if (this.$route.query.searchCode) {
      this.searchCode = String(this.$route.query.searchCode);
    }
    let query = this.routerReplacer();

    this.loading = true;
    await DemandSupplyStore.getAdminSales(query);
    this.loading = false;
  }
}
